<template>
  <div>
    <div class="row">
      <div class="col-6 my-auto">
        <p class="mb-0">Featured Videos</p>
      </div>
      <div class="col-6 my-auto">
        <FilterBar :list="list"></FilterBar>
      </div>
    </div>
    
    <!-- Table -->
    <vuetable data-path="featured"
              ref="vuetable"
              @vuetable:pagination-data="onPaginationData"
              pagination-path="pagination"
              :api-url="apiUrl"
              :fields="fields"
              :http-options="httpOptions"
              :css="css.table"
              :per-page="10"
              :append-params="moreParams"
              :query-params="{
                sort: 'sort',
                page: 'page',
                perPage: 'perPage'
              }"
              detail-row-component="featured-detail"
              track-by="featured_id"
              @vuetable:cell-clicked="onCellClicked">
      <template slot="action-item" slot-scope="props">
        <button class="btn btn-sm btn-outline-danger"
                @click="removeFeatured(props.rowData)"
                type="button">
          <i class="far fa-trash-alt"></i> Remove
        </button>
      </template>
    </vuetable>

    <!-- Pagination -->
    <div class="d-flex align-items-center justify-content-end">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination ref="pagination"
                           @vuetable-pagination:change-page="onChangePage"
                           :css="css.pagination">
      </vuetable-pagination>
    </div>
  </div>
</template>

<script>
  /* eslint-disable camelcase */
  import Vue from 'vue';
  import TableStyle from '../table-bootstrap-css.js';

  const Vuetable = () => import('vuetable-2/src/components/Vuetable');
  const VuetablePagination = () =>
  import('vuetable-2/src/components/VuetablePagination');
  const VuetablePaginationInfo = () =>
  import('vuetable-2/src/components/VuetablePaginationInfo');
  const FeaturedDetail = () => import('./FeaturedDetail.vue');
  const FilterBar = () => import('./FilterBar.vue');

  Vue.component('featured-detail', FeaturedDetail);

  export default {
    name  : 'FeaturedList',
    props : {
      update : Boolean,
    },
    data() {
      return {
        list        : 'featured',
        apiUrl      : process.env.VUE_APP_ROOT_API + 'api/media/featuredAdmin',
        moreParams  : {},
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [
          {
            name       : 'featured_id',
            title      : 'Featured<br>ID',
            sortField  : 'featured_id',
            direction  : 'desc',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
          {
            name      : 'media_title',
            title     : 'Title',
            sortField : 'media_title',
            direction : 'desc',
          },
          {
            name       : '__slot:action-item',
            title      : 'Action',
            titleClass : 'text-center',
            dataClass  : 'text-center',
          },
        ],
      }
    },
    components : {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
      FilterBar,
    },
    watch : {
      update(value) {
        if (value) {
          this.$refs.vuetable.refresh();
          this.$emit('update-featured-table');
        }
      },
    },
    methods : {

      /**
       * Transform data
       *
       * @param data
       * @return {object}
       */
      transform(data) {
        const transformed = {};

        if (data.data.length === 0 && data.currentPage > 1) {
          // redirect to previous page
          this.onChangePage(data.currentPage - 1);
        } else {
          transformed.pagination = {
            total         : data.total,
            per_page      : data.perPage,
            current_page  : data.currentPage,
            last_page     : data.lastPage,
            next_page_url : data.nextPageUrl,
            prev_page_url : data.prevPageUrl,
            from          : data.from,
            to            : data.to,
          };

          transformed.featured = [];

          for (let i = 0; i < data.data.length; i++) {
            transformed.featured.push({
              featured_id  : data.data[i].featured_id,
              media_id     : data.data[i].media_id,
              media_title  : data.data[i].media_title,
              media_type   : data.data[i].media_type,
              thumbnail    : data.data[i].thumbnail,
              wistia_id    : data.data[i].wistia_id,
              media_length : data.data[i].media_length,
              added        : data.data[i].added,
              created      : data.data[i].created,
            });
          }
        }

        return transformed;
      },

      /**
       * Set Pagination Info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$refs.pagination.setPaginationData(paginationData);
        this.$refs.paginationInfo.setPaginationData(paginationData);
      },

      /**
       * Change page
       * @param page
       */
      onChangePage(page) {
        this.$refs.vuetable.changePage(page);
      },

      /**
       * On Featured List Cell Clicked
       * @param data
       */
      onCellClicked(data) {
        this.$refs.vuetable.toggleDetailRow(data.featured_id);
      },

      /**
       * Remove Featured Media
       * @param data
       */
      removeFeatured(data) {
        this.$modal.show('dialog', {
          title   : 'Remove Featured Video',
          text    : 'Are you sure you want to remove ' + data.media_title + '?',
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.$http.delete('/api/media/featured', {
                data : {
                  mediaId : data.media_id,
                },
              }).then(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success!',
                  text  : 'Deleted ' + data.media_title +
                    ' from Featured List!',
                });
                this.$modal.hide('dialog');
                this.reloadTable();
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'error',
                  title : 'Failed',
                  text  : 'Oops! Something went wrong!',
                });
              });
            },
          }, {
            title : 'No',
          } ],
        });
      },

      /**
       * Reload Table
       */
      reloadTable() {
        this.$refs.vuetable.reload(); // reload vuetable
        this.$emit('update-media-table');
      },

      /**
       * On Filter Set
       * @param filterText
       */
      onFilterSet(filterText) {
        this.moreParams = {
          'filter' : filterText,
        };
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },

      /**
       * On Filter Reset
       */
      onFilterReset() {
        this.moreParams = {};
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      },
    },
    mounted() {
      this.$events.$on('filter-set-featured', eventData =>
        this.onFilterSet(eventData));
      this.$events.$on('filter-reset-featured', () => this.onFilterReset());
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/featured/featured-list";
</style>

